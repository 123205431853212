body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
* {
	margin: 0;
	padding: 0;
	outline: 0;
}

ul,
ol {
	list-style: none;
}
a {
	text-decoration: none;
}
