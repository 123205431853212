.navbar {
	width: 100%;
	background-color: black;
	/* position: -webkit-sticky !important; */
	/* position: sticky !important; */
	position: fixed;
	/* position: absolute; */
	/* top: 0 !important; */
	/* left: 0 !important; */
	z-index: 999;
}
.active-nav::after {
	content: "";
	background: #45ff9d;
	position: absolute;
	bottom: 0;
	left: 80px;
	height: 8px;
	width: 120px;
}

/* .service-top {
	background: url("./images/services/services-bg.png") top left no-repeat;
	background-size: cover;
} */

.coverage-full {
	width: "100%";
	height: "100%";
	background-image: url("./images/coverage/cov-new-bg.png");
	background-position: center center;
	/* background-size: 73% 100%; */
	background-size: cover;
	background-repeat: repeat-x;
	padding: "100px 0";
}

.pricing-top {
	background: url("./images/services/services-bg.png") top left no-repeat;
	background-size: cover;
	height: 65vh;
}
.tab-body .swiper-wrapper {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
@media (max-width: 600px) {
	.tab-body .swiper-wrapper {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
	}
}
.pricing-slide img {
	transition: all 0.3s ease-out;
}
.pricing-slide:hover img {
	transform: scale(1.1);
	cursor: pointer;
	/* transition: all 0.3s ease-out; */
}
.modal .close-btn {
	color: white;
	background-color: #540f8c;
	transition: all 0.5s linear;
}
.modal .close-btn:hover {
	color: #ff0000;
	background-color: #11bd61;
}
.modal .close-btn:hover .icon {
	transform: rotate(360deg);
	transition: 0.3s;
}

/* slide section  */
.tab {
	border: transparent;
	color: white;
}
.active-tab {
	/* border: transparent; */
	color: #a723ff;
	padding-bottom: 15px;
	border-bottom: 6px solid #45ff9d;
}

/* .active-tab::after{
	content: "";
	background: rgb(217, 160, 255);
	position: absolute;
	bottom: 0;
	left: 100%;
	height: 50%;
	width: 1000px;
} */

.slider-wrapper img {
	/* height: 60%; */
	width: 100%;
}

/* Delivery Component Start  */

.delivery {
	background: url("./images/services/services-bg.png") top left no-repeat;
	background-size: cover;
}
.delivery .delivery-right {
	background: url("./images/delivery/Template.png") top right no-repeat;
	background-size: cover;
}

/* Review section start  */

.review-full {
	background: url("./images/review/6\ 5fbd5f55328639.5a12b9089b658.gif") top
		left no-repeat;
	background-size: 97% 120%;
}
.review-bg {
	background-image: url("./images/review/1\ Black\ \(brand\ guideline\)\ rapido\ -\ Copy.png");

	height: 100%;
	width: 100%;
}
.review-full .swiper-button-prev,
.review-full .swiper-button-next {
	margin-top: 20px;
}

/* Story section start  */
.story-full {
	background: url("./images/services/services-bg.png") top left repeat-x;
	background-color: #490775;
}

.ict-group-img {
	-webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), to(rgba(250, 250, 250, 0.1)));
}

/* .story-full .swiper-button-next,
.story-full .swiper-button-prev {
	color: white;
	right: 10px;
	padding: 30px;
	color: #fff !important;
	fill: rgb(255, 255, 255) !important;
	stroke: #fff !important;
} */

/* App store section  */
.app-btn button {
	font-size: 25px;
	padding: 8px 15px;
	border-radius: 20px;
	border: none;
	margin: 15px 10px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	transition: all 0.4s ease-in-out;
	color: black;
}
.app-btn button:hover {
	background-color: #490775;
	color: #45ff9d;
	transform: scale(1.1);
}
.app-btn button:hover span {
	color: white;
}

/* Our clients*/
.our-clients {
	background: url("./images/register/2\ Wihte\(brand\ guideline\)\ rapido.png")
		top left no-repeat;
	background-size: cover;
}

/* footer section start  */
.footer-full {
	background: url("./images/services/services-bg.png") top left no-repeat;
	background-size: 100% 100%;
	position: relative;
	/* background-color: #490775; */
}
.footer-bottom {
	background: url("./images/footer/footer-bg.png") top left no-repeat;
	background-size: cover;
	padding-top: 50px;
}
.middle-item h4 {
	font-size: 30px;
	font-weight: normal;
	color: white;
	padding-bottom: 20px;
}
.middle-item ul li {
	margin: 15px 0;
}
/* .middle-item .social-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
} */
.middle-item ul li a {
	color: white;
	font-size: 16px;
}
.social-link li a .icon {
	font-size: 40px;
}
/* .bottom-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
} */
/* .bottom-top .top-item {
	width: 18%;
} */
.bottom-top .top-item a {
	color: white;
	font-size: 20px;
}
/* .footer-svg {
	height: 100%;
	background-image: url("./images/footer/footer-bg.svg");
	background-size: 100% 100%;
	-o-background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	background-size: cover;
} */

.swiper-button-next,
.swiper-button-prev {
	width: 150px !important;
	height: 92px !important;
}

.swiper-button-next {
	background-image: url("./images/0\ swipe\ button.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}
.swiper-button-prev {
	background-image: url("./images/0\ swipe\ button.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
	transform: rotate(180deg);
}

.swiper-button-next::after,
.swiper-button-prev::after {
	display: none;
}

/* Registration Section  */

.login,
.register,
#ecom-register {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background: url("./images/register/2\ Wihte\(brand\ guideline\)\ rapido.png")
		top left no-repeat;
	background-size: cover;
}

.tab-body Col.tab-col {
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
}
.tab-body .tab-col label,
.tab-body .tab-col input,
.tab-body .tab-col select,
.tab-body .tab-col textarea,
.login-card label,
.login-card input[type="text"],
.login-card input[type="password"] {
	display: block;
	width: 95%;
}

.tab-body .tab-col input,
.tab-body .tab-col select,
.tab-body .tab-col textarea,
.login-card label,
.login-card input {
	padding: 5px 10px;
	font-size: 18px;
	border-radius: 8px;
	border: 1px solid transparent;
	margin: 0 auto 12px auto;
}
.tab-body .tab-col input:hover,
.tab-body .tab-col input:focus,
.login-card input:hover,
.login-card input:focus {
	border-color: gray;
}
.tab-form-btn {
	font-size: 28px;
	padding: 8px 25px;
	border-radius: 10px;
	background-color: #4b0287;
	color: white;
	transition: all 0.4s linear;
	border: 1px solid transparent;
	cursor: pointer;
}
.tab-form-btn:hover {
	background-color: black;
	color: #45ff9d;
	border-color: #45ff9d;
}
