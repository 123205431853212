.merchant-banner-one {
	background: url("../../../images/dashboard/pre-payment\ banner.png") top left
		no-repeat;
	background-size: cover;
}
.merchant-banner-two {
	background: url("../../../images/dashboard/pre-payment\ banner\ -\ Copy.png")
		top left no-repeat;
	background-size: cover;
}

/* create parcel */
label,
input {
	display: block;
}
/* .category-select .ant-select-in-form-item {
	border: 1px solid #5b0a82;
} */
.category-select .ant-select-in-form-item .ant-select-selector {
	border: 1px solid #5b0a82 !important ;
	border-radius: 10px !important;
	/* padding: 10px 0 !important; */
	font-size: 18px !important;
}
.create-parcel input[type="text"] {
	border: 1px solid #5b0a82;
	border-radius: 10px;
	padding: 5px 15px;
	font-size: 18px;
	background-color: transparent;
}
/* .create-parcel .ant-form-vertical .ant-form-item-label {
	padding: 0 !important;
} */
.create-order-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0;
}
.create-order-button-wrapper .cancel {
	padding: 8px 18px;
	font-size: 24px;
	color: #6a00bb;
	border: 3px solid #4f008b;
	border-radius: 10px;
	cursor: pointer;
	font-weight: bold;
}
.create-order-button-wrapper .cancel:hover {
	padding: 8px 18px;
	color: #00ffb3;
	border: 3px solid transparent;
	background-color: #47007e;
}
.create-order-button-wrapper .place-order {
	padding: 8px 18px;
	font-size: 24px;
	background-color: #47007e;
	border: 3px solid transparent;
	color: white;
	border-radius: 10px;
	font-weight: bold;
	cursor: pointer;
}
.create-order-button-wrapper .disable {
	padding: 8px 18px;
	font-size: 24px;
	background-color: gray;
	border: 3px solid transparent;
	color: white;
	border-radius: 10px;
	cursor: pointer;
}
.create-order-button-wrapper .place-order:hover {
	color: rgb(0, 255, 179);
}
